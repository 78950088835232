import React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import Seo from "../components/seo";
import FaqAccordion from "../components/faq-accordion";
import {IoVideocamOutline, IoTrophyOutline, IoFilterOutline} from "react-icons/io5";

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<Seo
				title="YouTube Random Comment Picker. Generate Random Winners for Your Giveaway."
				description="A truly random winner generator designed to randomly pick a comment from any YouTube video. Random comment picker for your YouTube sweepstakes, giveaways, and contests"
				lang="en"
				canonical="/youtube/"
			/>
			<section className="pt-5 bg-blob-red">
				<Container>
					<Row className="text-white align-items-center">
						<Col xs="12" md="6">
							<h1 className="font-weight-bold">
								YouTube Random Comment Picker
							</h1>
							<p className="mb-5">
								A powerful app that helps you randomly select and announce winners of your contest or giveaway by picking random comments of any <b>YouTube</b> video.
							</p>
							<Button size="lg" variant="light" as="a" href="https://app.arbitery.com/sign-up">Start Now</Button>
						</Col>
						<Col xs="12" md="6" className="d-flex justify-content-center pt-5">
							<Img className="w-100" fluid={data?.andTheWinners?.childImageSharp?.fluid} alt="Arbitery YouTube Giveaway Winners"/>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1442.7449 182.91" style={{ position: 'relative', top: '-1px' }}>
					<path
						d="m 0,182.91 48.046,-31.994 c 48.047,-31.995 144.14,-95.984 240.233,-95.984 96.093,0 192.186,63.989 288.279,69.288 96.094,5.699 192.187,-48.291 288.28,-53.291 96.093,-4.999 192.186,36.994 288.279,37.294 96.093,-0.3 192.187,-42.293 240.233,-63.989 L 1442.745,23.57 V 0 H 0 Z"
						fill="#B52B72"/>
				</svg>
			</section>
			<section className="py-5">
				<Container>
					<h2 className="text-center pb-5">How to Generate a Random Winner From a YouTube Video?</h2>
					<Row className="align-items-stretch">
						<Col xs="12" md="4">
							<div className="text-center mb-3 text-danger display-4">
								<IoVideocamOutline />
							</div>
							<h5>1. Select a YouTube Video</h5>
							<p>
								Enter a YouTube video URL that will be used to collect all the comments.
							</p>
						</Col>
						<Col xs="12" md="4">
							<div className="text-center mb-3 text-danger display-4">
								<IoFilterOutline />
							</div>
							<h5>2. Specify Additional Filters</h5>
							<p>
								Filter out duplicate comments or exclude comments with certain content.
							</p>
						</Col>
						<Col xs="12" md="4">
							<div className="text-center mb-3 text-danger display-4">
								<IoTrophyOutline />
							</div>
							<h5>3. Generate Random Winners</h5>
							<p>
								Automatically pick random comments and announce the winners of your giveaway.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 bg-light">
				<Container>
					<Row className="align-items-center">
						<Col xs="12" md="6" lg="7" className="d-flex justify-content-center mb-5 mb-md-0">
							<Img className="w-100" fluid={data?.commentPicker?.childImageSharp?.fluid} alt="Arbitery Youtube Comment Picker"/>
						</Col>
						<Col xs="12" md="6" lg="5">
							<h2>Pick a Random Comment from any YouTube Video</h2>
							<p>
								Using YouTube comments as contest entries is a great choice to incentivize engagement on your video.
								Arbitery makes it easy for you to select a winner from comments with its fully automatic and truly random comment picker.
							</p>
							<p className="mt-4">
								<Button variant="danger" as="a" size="lg" href="https://app.arbitery.com/sign-up">Get Started</Button>
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Card className="shadow-sm p-3">
						<Card.Body>
							<Row className="align-items-center">
								<Col xs="12" lg="9">
									<h3>
										Try Arbitery Risk-Free!
									</h3>
									<p>
										We offer a 30-day money-back guarantee. If you are not 100% satisfied with our YouTube random comment picker, we'll refund your payment. No hassle, no risk.
									</p>
								</Col>
								<Col xs="12" lg="3">
									<Button variant="danger" as="a" size="lg" href="https://app.arbitery.com/sign-up">Try Now Risk-Free</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Container>
			</section>
			<section className="py-5 bg-light">
				<Container>
					<Row className="align-items-center">
						<Col xs="12" md="6" className="d-flex justify-content-center mb-5 mb-md-0">
							<Img className="w-100" fluid={data?.announcement?.childImageSharp?.fluid} alt="Arbitery Youtube random winner picker" />
						</Col>
						<Col xs="12" md={{ order: 'first', span: 6 }} className="align-self-center">
							<h2>Run Engaging YouTube Giveaways, Sweepstakes & Competitions</h2>
							<p>
								You are looking for a way to get more people to engage with your YouTube channel?
								The “Comment to Win” contest is a great way to increase engagement on your account.
							</p>
							<p>
								Arbitery helps you to randomly select the winner from all the individuals who comment on a video.
								All you need is to enter the URL of the video and select the number of winners.
							</p>
							<p className="mt-4">
								<Button variant="danger" as="a" size="lg" href="https://app.arbitery.com/sign-up">Get Started</Button>
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5" id="how-it-works-section">
				<Container>
					<h2 className="text-center pb-5">How Does It Work?</h2>
					<Row>
						<Col xs="12" md="4">
							<h5>1. Enter Video URL</h5>
							<p>
								Enter the YouTube video URL that will be used to retrieve comments.
							</p>
						</Col>
						<Col xs="12" md="4">
							<h5>2. Pick a Winner</h5>
							<p>
								Arbitery automatically pulls the comments based on your criteria and randomly selects a winner.
							</p>
						</Col>
						<Col xs="12" md="4">
							<h5>3. Post an Announcement</h5>
							<p>
								Create an announcement post to share the chosen winners on your social media accounts.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5" id="faq">
				<Container>
					<div className="text-center pb-3">
						<h2>Frequently Asked Questions (FAQs)</h2>
						<p>Do you have a question about Arbitery? See the list below for our most frequently asked questions.</p>
					</div>
					<Row>
						<Col xs="12" lg="8" className="mx-auto mb-3">
							<FaqAccordion type="youtube" />
						</Col>
					</Row>
					<div className="text-center">
						<p className="text-muted">Have a question that isn't listed here? <a href="https://go.crisp.chat/chat/embed/?website_id=f793a958-55b5-4cd0-ab49-a88e01cb8382">Get in touch!</a></p>
					</div>
				</Container>
			</section>
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    commentPicker: file(relativePath: { eq: "youtube_comment_picker.png" }) {
      childImageSharp {
        fluid(maxWidth: 476) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    andTheWinners: file(relativePath: { eq: "and_the_winners_are_youtube.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    announcement: file(relativePath: { eq: "youtube_winner_picker.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    faceF: file(relativePath: { eq: "face_f.jpeg" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    faceM: file(relativePath: { eq: "face_m.jpeg" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
